// @flow
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SettingsPage from '../SettingsPage';
import EligitablityRules from '../eligibility-rules/EligibilityRules';
import EditAllowlist from '../allowlist/EditAllowlist';
import Logs from '../log/Logs';

const SettingManagerSubRoutes = () => (
  <Routes>
    <Route path="setting" element={<SettingsPage />} />
    <Route path="eligibility-rules" element={<EligitablityRules />} />
    <Route path="edit-allowlist" element={<EditAllowlist />} />
    <Route path="logs" element={<Logs />} />
  </Routes>
);

export default SettingManagerSubRoutes;
