import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { httpGet, httpPut } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';

const COMPANY_FEATURE_API = '/v1/company/:sfAccountId/company-feature';

const useFeatures = sfAccountId => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFeatures = useCallback(async () => {
    try {
      setLoading(true);
      const features = await httpGet(COMPANY_FEATURE_API.replace(':sfAccountId', sfAccountId));
      setFeatures(features);
      return features;
    } catch (error) {
      setFeatures([]);
      console.error('Error fetching features:', error);
    } finally {
      setLoading(false);
    }
  }, [sfAccountId]);

  useEffect(() => {
    if (sfAccountId) {
      executeWithSpinner(fetchFeatures());
    }
  }, [sfAccountId, executeWithSpinner, fetchFeatures]);

  const updateFeatures = async features => {
    try {
      const updatedFutures = buildUpdateRequest(features);
      await executeWithSpinner(
        httpPut(COMPANY_FEATURE_API.replace(':sfAccountId', sfAccountId), updatedFutures)
      );
      setFeatures(updatedFutures);
    } catch (error) {
      console.error(error);
    }
  };

  const buildUpdateRequest = useCallback(
    feats => {
      return features.map(feat => {
        const found = feats.find(({ feature }) => feature === feat.feature);
        return {
          ...feat,
          enabled: found ? found.enabled : feat.enabled,
        };
      });
    },
    [features]
  );

  const isFeatureEnabled = useCallback(
    feat => {
      const found = features.find(({ feature: f } = {}) => f === feat);
      return !!found?.enabled;
    },
    [features]
  );

  const enabledFeatures = useMemo(() => {
    return features.filter(({ enabled }) => enabled).map(({ feature }) => feature);
  }, [features]);

  return {
    features,
    enabledFeatures,
    updateFeatures,
    isFeatureEnabled,
    loading,
  };
};

export default useFeatures;
