import React, { useEffect } from 'react';
import HorizontalFormRow from 'app/ui/components/horizontal-form/HorizontalFormRow';
import styles from '../EligibilityRules.module.scss';

import SimpleCheckBox from '../../../../../../components/SimpleCheckBox';

const EligibilityOptions = ({
  keyField,
  headers,
  body,
  selectedOptions,
  setSelectedOptions,
  optionType,
  changeEligibilityOptionsField,
}) => {
  useEffect(() => {}, []);
  const handleCheckBoxChange = uuid => {
    const checkListCopy = new Set(selectedOptions);
    if (!checkListCopy.has(uuid)) {
      checkListCopy.add(uuid);
    } else {
      checkListCopy.delete(uuid);
    }
    setSelectedOptions({ action: optionType, payload: checkListCopy });
    changeEligibilityOptionsField(optionType, [...checkListCopy].join(','));
  };

  return (
    <HorizontalFormRow>
      <div className={`col-md-6 ${styles.availableOptionsTable}`}>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              {headers.map(h => (
                <th key={h.key}>{h.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((record, j) => {
              return (
                <tr key={j}>
                  <td>
                    <SimpleCheckBox
                      controlId={record[keyField]}
                      checked={selectedOptions.has(record[keyField])}
                      onChange={() => handleCheckBoxChange(record[keyField])}
                    />
                  </td>
                  {headers.map((h, i) => {
                    return <td key={`${i}${j}`}>{record[h.key] || ''}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </HorizontalFormRow>
  );
};

export default EligibilityOptions;
