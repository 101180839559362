import type { MembershipOfferFrontendModel } from '../../../types';
import {
  convertToPeriod,
  extractDayFromPeriod,
  extractMonthFromPeriod,
} from '../../../../utils/date/periodUtil';
import { convertCetDateToUtc } from 'app/utils/date/dateUtil';

const DEFAULT_CURRENCY = 'EUR';

export const covertMembershipOfferToMembershipOfferFrontendModel = (
  membershipOffer: ?MembershipOfferFrontendModel
): MembershipOfferFrontendModel => {
  return {
    ...membershipOffer,
    amount: membershipOffer?.price ? membershipOffer.price.amount : undefined,
    currency: membershipOffer?.price ? membershipOffer.price.currency : DEFAULT_CURRENCY,
    deadlineDay: membershipOffer ? extractDayFromPeriod(membershipOffer.signupDeadline) : 20,
    deadlineMonth: membershipOffer ? extractMonthFromPeriod(membershipOffer.signupDeadline) : -1,
    sfAccountCanonicalId: membershipOffer?.sfAccountCanonicalId
      ? membershipOffer.sfAccountCanonicalId
      : undefined,
  };
};

export const convertMembershipOfferFrontendModelToMembershipOffer = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferFrontendModel => ({
  id: membershipOffer.id,
  price: {
    amount: membershipOffer.amount,
    currency: membershipOffer.currency.toUpperCase(),
  },
  b2cPayment: membershipOffer.b2cPayment,
  customTermsDescription: membershipOffer.customTermsDescription,
  employeeInternalIdentifierLabel: membershipOffer.employeeInternalIdentifierLabel,
  employeeInternalIdentifierValidationRegex:
    membershipOffer.employeeInternalIdentifierValidationRegex,
  employeeInternalIdentifierValidationDescription:
    membershipOffer.employeeInternalIdentifierValidationDescription,
  signupDeadline: convertToPeriod(membershipOffer.deadlineMonth, membershipOffer.deadlineDay),
  sfAccountCanonicalId: membershipOffer.sfAccountCanonicalId,
  type: membershipOffer.type,
  availableFrom: convertCetDateToUtc(membershipOffer.availableFrom, 'DD-MM-YYYY HH:mm'),
  availableTo: convertCetDateToUtc(membershipOffer.availableTo, 'DD-MM-YYYY HH:mm'),
  sfAccountCountryCode: membershipOffer.sfAccountCountryCode,
});
