import React, { useEffect, useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet } from 'app/service/http';
import { notificationService } from 'app/service/notification';
import EligibilityOptions from './EligibilityOptions';
import { ELIGIBILITY_RULE_OPTION_ACTIONS } from '../../../reducers/useEligibilityRuleOption';

const LegalEntityOptions = ({
  eligibilityRuleOption,
  setEligibilityRuleOption,
  changeEligibilityOptionsField,
  savedEligibilityRules,
  integrationScopeId,
}) => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = [
    {
      key: 'legalEntityId',
      label: 'Legal Entity Id',
    },
    {
      key: 'legalEntityName',
      label: 'Legal Entity Name',
    },
  ];

  useEffect(() => {
    const fetchLegalEntityOptions = async () => {
      try {
        setLoading(true);
        await httpGet(`/v1/integration-scope/${integrationScopeId}/legal-entity`).then(data => {
          if (!data.length) {
            notificationService.push({
              message: 'No legal entities found',
              variant: 'warning',
            });
            return;
          }
          const transformedLegalEntities = data?.map(obj => ({
            legalEntityId: obj.id != null ? obj.id : 'EMPTY',
            legalEntityName: obj.name,
          }));

          savedEligibilityRules?.split(',').forEach(legalEntity => {
            if (!transformedLegalEntities.some(b => b.legalEntityId === legalEntity)) {
              transformedLegalEntities.push({
                legalEntityId: legalEntity,
                legalEntityName: 'Not found',
              });
            }
          });
          setBody(transformedLegalEntities);
        });
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(fetchLegalEntityOptions());
  }, [executeWithSpinner, savedEligibilityRules, integrationScopeId]);

  return (
    !loading &&
    body.length > 0 && (
      <EligibilityOptions
        keyField={'legalEntityId'}
        headers={headers}
        body={body}
        selectedOptions={eligibilityRuleOption}
        setSelectedOptions={setEligibilityRuleOption}
        optionType={ELIGIBILITY_RULE_OPTION_ACTIONS.SET_LEGAL_ENTITIES}
        changeEligibilityOptionsField={changeEligibilityOptionsField}
      />
    )
  );
};

export default LegalEntityOptions;
