import React, { useCallback, useContext, useRef } from 'react';
import HorizontalFormLabel from 'app/ui/components/horizontal-form/HorizontalFormLabel';
import HorizontalFormRow from 'app/ui/components/horizontal-form/HorizontalFormRow';
import HorizontalFormTextField from 'app/ui/components/horizontal-form/HorizontalFormTextField';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { httpPost } from 'app/service/http';
import moment from 'moment';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';

const HrisConnectionForm = ({
  integrationScope: { remoteIntegrationId, lastSyncedAt, id } = {},
  fetchIntegrationScope,
}) => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { sfAccountCanonicalId } = useParams();
  const formRef = useRef();

  const readOnly = Boolean(id);

  const onSubmit = useCallback(
    async submitBody => {
      if (submitBody && sfAccountCanonicalId) {
        const requestBody = {
          remoteIntegrationId: submitBody.remoteIntegrationId,
          externalId: sfAccountCanonicalId,
          source: 'WELLPASS',
          type: 'COMPANY',
        };

        await executeWithSpinner(httpPost(`/v1/integration-scope`, requestBody));
        await executeWithSpinner(fetchIntegrationScope());
      }
    },
    [executeWithSpinner, sfAccountCanonicalId, fetchIntegrationScope]
  );

  return (
    <>
      <h5>HRIS connection</h5>

      <Form
        onSubmit={onSubmit}
        key={id}
        initialValuesEqual={() => true}
        initialValues={{
          id,
          remoteIntegrationId,
        }}
        render={({ handleSubmit, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              {readOnly && (
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Integration Scope ID" />
                  <HorizontalFormTextField
                    controlId="id"
                    controlLabel="integrationScopeId"
                    placeholder="Integration UUID"
                    columnWidth={6}
                    disabled={true}
                    helpTextAfter={`Last sync: ${
                      lastSyncedAt
                        ? moment(lastSyncedAt).format('DD.MM.YY h:mm:ss a')
                        : 'Not Present'
                    }`}
                    default
                  />
                </HorizontalFormRow>
              )}
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Remote Integration ID" />
                <HorizontalFormTextField
                  controlId="remoteIntegrationId"
                  controlLabel="Remote Integration ID"
                  placeholder="Remote Integration ID"
                  columnWidth={6}
                  disabled={readOnly}
                  helpTextAfter={!readOnly && 'example: personio:CBNMt7dSNCzBdnRTx87dev4E'}
                />
              </HorizontalFormRow>

              {!readOnly && (
                <button className="btn btn-block btn-secondary col-sm-3" type="submit">
                  Save
                </button>
              )}
            </form>
          );
        }}
      />
    </>
  );
};

export default HrisConnectionForm;
