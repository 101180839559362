import React, { createContext } from 'react';
import useFeatures from './hooks/useFeatures';
import { useParams } from 'react-router-dom';

const COMPANY_FEATURES = {
  MEMBERSHIP_MANAGEMENT: {
    name: 'MEMBERSHIP_MANAGEMENT',
    description:
      'Controls the visibility of the “Membership Management” feature in the Company Portal. This feature enables HR managers to view the list of subscribed members within a company, cancel memberships, and export member records as a CSV file.',
  },
  ALLOWLIST: {
    name: 'ALLOWLIST',
    description:
      'Manages the visibility of the “Allowlist” feature in the Company Portal. This feature allows HR managers to download a list of eligible employees, either synced from a connected HRIS system or managed manually. When managed manually, it also enables editing the list of eligible employees directly.',
  },
  CORPORATE_CONTROLLING: {
    name: 'CORPORATE_CONTROLLING',
    description:
      'Enables additional employee eligibility checks during the Wellpass sign-up process through integration with a connected HRIS system.',
  },
};

const STATE = {
  features: [],
  enabledFeatures: [],
  loading: false,
  updateFeatures: features => {},
  isFeatureEnabled: feature => false,
};

const CompanyFeaturesContext = createContext(STATE);

const CompanyFeaturesContextProvider = ({ children }) => {
  const { sfAccountCanonicalId } = useParams();
  const { features, enabledFeatures, updateFeatures, isFeatureEnabled, loading } =
    useFeatures(sfAccountCanonicalId);

  return (
    <CompanyFeaturesContext.Provider
      value={{
        features,
        enabledFeatures,
        loading,

        updateFeatures,
        isFeatureEnabled,
      }}
    >
      {children}
    </CompanyFeaturesContext.Provider>
  );
};

export { CompanyFeaturesContextProvider, COMPANY_FEATURES };
export default CompanyFeaturesContext;
