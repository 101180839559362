import React, { useEffect, useState } from 'react';
import { formatISOZonedDateTimeToUTCDateTime } from '../../../../utils/format/dateTimeFormatter';
import { Link, useParams } from 'react-router-dom';
import JSONPretty from 'react-json-pretty';
import useLogs from './hooks/useLogs';

const Logs = () => {
  const sfAccountCanonicalId = useParams().sfAccountCanonicalId;
  const [page, setPage] = useState(1);
  const [changelogs, setChangelogs] = useState([]);
  const {
    changelogs: pageChangelogs,
    maxPage,
    forceFetchLogs,
  } = useLogs(sfAccountCanonicalId, { page });

  useEffect(() => {
    setChangelogs(prevChangelogs => [...prevChangelogs, ...pageChangelogs]);
  }, [pageChangelogs]);

  const resetLogs = () => {
    setChangelogs([]);
    if (page > 1) {
      setPage(1);
      return;
    }
    forceFetchLogs();
  };

  return (
    <>
      <div className="my-md-1">
        <div className="my-md-1">
          <button className="btn btn-secondary" type="button" onClick={resetLogs}>
            Reload newer logs
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Actor Type</th>
              <th>Actor ID</th>
              <th>Object Type</th>
              <th>Object ID</th>
              <th>Audit Type</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {changelogs?.map(changelog => (
              <tr key={changelog.id}>
                <td>{formatISOZonedDateTimeToUTCDateTime(changelog.timestamp)}</td>
                <td>{changelog.referencedActorType}</td>
                <td>
                  {changelog.actorUserId ? (
                    <Link to={`/users/user/${changelog.actorUserId}/details`}>
                      {changelog.actorUserId}
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{changelog.referencedObjectType}</td>
                <td>{changelog.referencedObjectId}</td>
                <td>{changelog.auditType}</td>
                <td>
                  <JSONPretty data={JSON.parse(changelog.message)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-md-1">
        <button
          className="btn btn-secondary"
          type="button"
          disabled={page >= maxPage}
          onClick={() => setPage(page + 1)}
        >
          Load older logs
        </button>
      </div>
    </>
  );
};

export default Logs;
