import React, { useContext, useEffect, useMemo, useState } from 'react';
import CompanyFeaturesContext, { COMPANY_FEATURES } from '../../context/CompanyFeaturesContext';

const STATUS_OPTIONS = [
  {
    label: 'ACTIVE',
    labelAction: 'Activate',
    value: true,
    info: 'Eligibility checks are applied on the sign-up page via the HRIS system.',
    style: 'badge-primary',
  },
  {
    label: 'INACTIVE',
    labelAction: 'Deactivate',
    value: false,
    info: 'Eligibility checks are not applied on the sign-up page',
    style: 'badge-light text-muted',
  },
];

const HrisConnectionStatusForm = () => {
  const { updateFeatures, isFeatureEnabled } = useContext(CompanyFeaturesContext);

  const savedEnabledValue = useMemo(
    () => isFeatureEnabled(COMPANY_FEATURES.CORPORATE_CONTROLLING.name),
    [isFeatureEnabled]
  );

  return (
    <div className="my-4">
      <h5>HRIS connection status</h5>
      <CurrentStatus enabled={savedEnabledValue} />
      <UpdateStatus initialValue={savedEnabledValue} saveStatus={updateFeatures} />
    </div>
  );
};

const CurrentStatus = ({ enabled }) => {
  const { label, info, style } = STATUS_OPTIONS.find(option => option.value === Boolean(enabled));
  return (
    <div className="row py-2">
      <label className="col-sm-3">Current Status</label>
      <div className="col-sm-6 d-flex align-items-center">
        <span className={`${style} badge badge-pill d-flex align-items-center`}>{label}</span>
        <i className="fas fa-info-circle text-secondary cursor-pointer pl-2" title={info}></i>
      </div>
    </div>
  );
};

const UpdateStatus = ({ initialValue, saveStatus }) => {
  const [enabled, setEnabled] = useState(false);
  const { labelAction } = STATUS_OPTIONS.find(option => option.value === Boolean(enabled));

  useEffect(() => {
    setEnabled(initialValue);
  }, [initialValue]);

  const handleToggle = () => {
    setEnabled(!enabled);
  };

  const buildUpdateRequest = () => {
    return [
      {
        feature: COMPANY_FEATURES.CORPORATE_CONTROLLING.name,
        enabled,
      },
    ];
  };

  const onSave = () => {
    const request = buildUpdateRequest();
    saveStatus(request);
  };

  return (
    <div className="row py-2">
      <label className="col-sm-3">Change Status</label>
      <div className="col-sm-9">
        <div className="custom-control custom-switch d-flex align-items-center pb-4">
          <input
            id="switch"
            type="checkbox"
            className="custom-control-input"
            checked={enabled}
            onChange={handleToggle}
          />
          <label className="custom-control-label cursor-pointer" htmlFor="switch">
            {labelAction}
          </label>
        </div>
      </div>
      <div className="col-sm-3">
        <button
          className="btn btn-secondary w-100"
          disabled={initialValue === enabled}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default HrisConnectionStatusForm;
