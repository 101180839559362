import React, { useCallback, useEffect } from 'react';
import { httpGet } from '../../../../service/http';
import EligibilityRulesForm from './components/EligibilityRulesForm/EligibilityRulesForm';
import VerificationOptionsForm from './components/VerificationOptionsForm';
import { useParams } from 'react-router-dom';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import HrisConnectionForm from './components/HrisConnectionForm';
import HrisConnectionStatusForm from './components/HrisConnectionStatusForm';

const EligibilityRules = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { sfAccountCanonicalId } = useParams();
  const [integrationScope, setIntegrationScope] = React.useState();
  const showConnectionOptions = Boolean(integrationScope?.id);

  const fetchIntegrationScope = useCallback(async () => {
    try {
      if (!sfAccountCanonicalId) {
        return;
      }
      const integrationScope = await httpGet(
        `/v1/integration-scope?externalId=${sfAccountCanonicalId}`
      );
      if (!integrationScope) {
        return;
      }
      setIntegrationScope(integrationScope);
    } catch (error) {
      console.error('Error fetching the latest integration scope', error);
    }
  }, [sfAccountCanonicalId]);

  const fetchIntegrationScopeWithSpinner = useCallback(async () => {
    await executeWithSpinner(fetchIntegrationScope());
  }, [executeWithSpinner, fetchIntegrationScope]);

  useEffect(() => {
    fetchIntegrationScopeWithSpinner();
  }, [fetchIntegrationScopeWithSpinner]);

  return (
    <div className="mt-4">
      <HrisConnectionForm
        integrationScope={integrationScope}
        fetchIntegrationScope={fetchIntegrationScope}
      />
      {showConnectionOptions && (
        <>
          <HrisConnectionStatusForm />
          <EligibilityRulesForm integrationScope={integrationScope} />
          <VerificationOptionsForm integrationScopeId={integrationScope?.id} />
        </>
      )}
    </div>
  );
};

export default EligibilityRules;
