// @flow
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OfferManager from '../offer-details/OfferManager';

const OfferManagerSubRoutes = () => (
  <Routes>
    <Route path="details" element={<OfferManager />} />
    <Route index element={<Navigate to="details" />} />
  </Routes>
);

export default OfferManagerSubRoutes;
